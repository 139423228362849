@use "_variables";
@use "_textStyles";
@use "_animations";
@use "_mediaQueries";

$card-width: 280px;
$card-height: 227px;

.auditSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: variables.$base-factor * 10;
  box-sizing: border-box;
  padding-top: variables.$base-factor * 15;
  border-top: 1px solid variables.$white107;

  &Wrapper {
    margin-bottom: variables.$base-factor * 15;
    background-color: variables.$white100;
    width: 100%;
    box-sizing: content-box;
  }

  @include mediaQueries.custom(max-width, 1000px) {
    gap: variables.$base-factor * 10;
  }

  & > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h2 {
      margin-top: variables.$base-factor * 3;
      margin-bottom: variables.$base-factor * 3;
    }

    p {
      text-align: center;
    }
  }

  & > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: variables.$base-factor * 7;

    @include mediaQueries.xl(max-width) {
      flex-direction: column;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  & > div > :global(.tb-tag),
  & > div > h2,
  & > div > p {
    opacity: 0;
    transform: translateY(20%);
  }

  &HeadingAppear {
    /* stylelint-disable-next-line selector-class-pattern */
    :global(.tb-tag) {
      @include animations.animate(fadeInUp, 1s, $timing-function: ease-out);
    }

    h2 {
      @include animations.animate(fadeInUp, 1s, 0.25s, $timing-function: ease-out);
    }

    p {
      @include animations.animate(fadeInUp, 1s, 0.5s, $timing-function: ease-out);
    }
  }
}

.card {
  opacity: 0;
  flex: 1;
  width: $card-width;
  height: $card-height;
  border-radius: variables.$border-radius4;
  padding: variables.$base-factor * 10;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 1px solid variables.$white107;
  border-bottom: 5px solid variables.$white107;
  transition: opacity 1s ease-out, transform 0.15s ease-out, background-color 0.15s ease-out;

  h3 {
    @include textStyles.body;

    margin-bottom: variables.$base-factor * 3;
    font-size: 18px !important;
  }

  &Content {
    font-size: 14px;
  }

  @include mediaQueries.lg(max-width) {
    padding: variables.$base-factor * 5;
  }

  @include mediaQueries.xl(max-width) {
    padding: variables.$base-factor * 10;
    flex-direction: row;
    align-items: center;
    aspect-ratio: unset;
    width: 80%;
    gap: variables.$base-factor * 10;
  }

  @include mediaQueries.md(max-width) {
    width: 100%;
  }

  &.cardAppear {
    opacity: 1;
    transform: translateY(0);

    @include mediaQueries.md(min-width) {
      &:hover {
        transform: translateY(-10px);
      }
    }
  }

  & > div:first-child {
    a {
      display: inline-flex;
    }

    &Title,
    &Content {
      margin-bottom: variables.$base-factor * 3;
    }

    .cardLabel {
      @include textStyles.tag;

      font-size: 10px;
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      color: variables.$black100;

      svg {
        margin-right: variables.$base-factor * 2;
        width: variables.$base-factor * 4;
        stroke-width: 1.5px;
      }
    }
  }

  @include mediaQueries.md(min-width) {
    &:hover {
      transform: translateY(-10px);
    }
  }

  &:first-child {
    background-color: variables.$yellow60;
    border-color: variables.$yellow80;

    &:hover {
      background-color: variables.$yellow70;
    }
  }

  &:nth-child(2) {
    background-color: variables.$green60;
    border-color: variables.$green80;

    &:hover {
      background-color: variables.$green70;
    }
  }

  &:nth-child(3) {
    background-color: variables.$orange60;
    border-color: variables.$orange80;

    &:hover {
      background-color: variables.$orange70;
    }
  }

  &:last-child {
    background-color: variables.$purple60;
    border-color: variables.$purple80;

    &:hover {
      background-color: variables.$purple70;
    }
  }
}
